<template>
  <WidgetFrame>
    <template v-slot:title>
      {{ $t('permission.milestones') }}
    </template>
    <template v-slot:content>
      <div v-if="!canView('PROJECT', ['TASK'])" class="center-text">
        {{ $t('entity_selector.error.insufficient_permission_to_show_data') }}
      </div>
      <template v-else-if="!loaded">
        <div class='status-message'>Loading...</div>
      </template>
      <template v-else>
        <MilestoneList class="staff-list" v-if="loaded" :milestones="milestones" @editTask='editTask' />
      </template>
    </template>
  </WidgetFrame>
</template>
<script>
  import * as moment from 'moment-timezone';
  moment.tz.setDefault('Etc/UTC');
  import { taskService } from '@/services'
  
  export default {
    name: 'ProjectMilestonesWidget',
    components: {
      MilestoneList: () => import('@/components/Dashboard/Widget/List/MilestoneList.vue'),
      WidgetFrame: () => import('@/components/Dashboard/WidgetFrame'),
    },
    props: {
      project:    { type: Object, required: true }
    },
    watch: {
      tasks: function () {
        this.buildData();
      },
    },
    data() {
      return {
        loaded: false,
        milestones: [],
      };
    },
    created() {
      this.buildData();
    },
    methods: {
      async buildData() {
        this.milestones = [];
        const self = this;
        /**
         * Status is Completed when task.progress = 1
         * Status is Late when task.startDate is earlier than current date
         * Status is Soon when task.startDate is earlier than current date + 7 days
         * Status is Pending otherwise
         */
        const now = moment();
        const tasks = await taskService.listProjectDashboardMilestones(this.project.uuId).then(response => {
          return response.data;
        }).catch(e => {
          // eslint-disable-next-line
          console.error(e);
        });
        
        tasks.forEach(function(task) {
          if (task.type == "Milestone") {
            const start = moment(task.startTime);
            const nowPlus7 = moment(now).add(7,'d');
            const name = [task.path, task.name].join(' / ');
            
            let status;
            if (task.progress == 1) {
              status = 'complete';
            } else if (start.isBefore(now)) {
              status = 'late';
            } else if (start.isBefore(nowPlus7)) {
              status = 'soon';
            } else {
              status = 'pending';
            }
            self.milestones.push({
              'status': status, 'date': task.endTime, 'name': name,
              'uuId': task.uuId, 'projectId': self.project.uuId});
          }
        })
        this.loaded = true;
      },
      editTask(params) {
        this.$emit('editTask', params);
      }
    }
  }
</script>

<style scoped>
  .staff-list {
    flex-grow: 1;
  }

  .center-text {
    text-align: center;
    margin: auto;
  }
</style>